@import url('https://fonts.cdnfonts.com/css/lab-grotesque');

body {
  margin: 0;
  font-family: 'Lab Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181c14;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  @apply w-72 cursor-pointer border-b-4 rounded-md bg-beige-medium h-44 text-center px-3 py-7 self-end;
}

.card-active {
  @apply h-96;
}

.card-icon {
  @apply text-5xl mt-2 max-w-[5rem] mx-auto min-w-[5rem] min-h-[5rem] rounded-full bg-olive-light text-orange-light grid place-content-center;
}

.card-text {
  @apply mt-3 p-5;
}
